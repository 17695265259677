import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { ComponentType, useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useAuthGuard } from '../auth';

const Layout: ComponentType = props => {
  const { children } = props;
  const [isPreloaded, setIsPreloaded] = useState(true);

  useAuthGuard();

  const timeout = useRef<any>(0);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setIsPreloaded(true);
    }, 100);
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: data.site.siteMetadata.description },
          { name: 'keywords', content: data.site.siteMetadata.keywords },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]}
      >
        <html lang="de" />
      </Helmet>
      <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
        {children}
      </div>
      <div id="footer" className="section has-background-dark has-text-white">
        <div className="container">
          fakeOS &copy; {new Date().getFullYear()}
          <ul className="horizontal">
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
            <li>
              <Link to="/datenschutz">Datenschutzrichtlinien</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Layout;
